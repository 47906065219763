import styled from "styled-components";

export const StyledTariff = styled.div`
  width: 230px;
  height: 280px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 0px rgba(204, 204, 204, 1);
  transition: 300ms;
  background-color: #fff;
  transition: 300ms;
  @media (max-width:995px) {
    margin-top:15px;
  }
  .price {
    background: rgb(63,121,186);
background: linear-gradient(90deg, rgba(63,121,186,1) 0%, rgba(0,69,146,1) 100%);
    width: 100%;
    height:50px;
    padding: 2px;
    padding-top:5px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    display:flex;
    justify-content:center;
    align-items:center;
    span {
      font-size:12px;
      }
    h3 {
      font-family: "BOG 2017 Headline";
      font-size: 26px;
      color:#fff;
      position:relative;
      span {
        position:absolute;
        right:-13px;
        top:7px;
        font-family: "BOG 2018";
      }
    }
    h4 {
      display:flex;
      flex-direction:column;
      margin-top:7px;
      margin-left:3px;
      color:#fff;
      font-size:12px;
    }
  }
  &:hover {
    z-index: 111;
    transform: scale(1.04);
  }
  .head {
    width: 100%;
    text-align: center;
    font-family: "BOG 2017 Headline";
    color: #282828;
    padding: 5px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0;
    }
    h1 {
      font-size: 30px;
      color: #004592;
    }
    h3 {
      color: #282828;
      font-size: 16px;
      margin-top:15px;
    }
    h4 {
      color: #969696;
    }
  }
  ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: #f5f5f5 1px solid;
    span {
      font-size: 12px;
      color: #7f848a;
    }
  }
  .order__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledPacketInfoButton = styled.button`
  padding: 4px;
  font-size:12px;
  background:transparent;
  border:0;
  display:flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  p {
    margin-top:5px;
  }
  svg {
    font-size:25px;
  }
`;

export const StyledInfoList = styled.ul`
position: relative;
display:flex;
 justify-content: flex-start;
 align-items: flex-start;
 flex-direction: column;
.logo {
  width:100px;
  margin-bottom: 20px;
}
  li {
    list-style:none;
    display:flex;
    justify-content:space-between;
    align-items:center;
    font-size:12px;
    width:100%;
    margin-top:8px;
    box-shadow: 0px 0px 3px 0px rgba(204, 204, 204, 1);
    border-radius:3px;
    padding:8px;
  }
`


export const StyledOrderButton = styled.button`
  padding: 10px;
  width: 155px;
  border: 0;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color: #282828;
  border-radius: 3px;
  cursor: pointer;
  transition: 300s;
  border: 1px solid #004592;
  background: transparent;
  margin-top: 5px;
  transition: 300ms;
  &:hover {
    border: 1px solid rgba(253,97,124,1);
    background: linear-gradient(90deg, rgba(253,97,124,1) 0%, rgba(235,0,41,1) 100%);
    color:#fff;
  }
`;

export const StyledContainer = styled.div`
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom:20px;
  margin-top:10px;
  .tariff__container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top:25px;
  }
  .section__title {
    h1 {
      font-family: "BOG 2017 Headline";
      font-size: 16px;
      color: #0e4c90;
      text-align: center;
      padding-top: 15px;
      i {
        color: #0e4c90;
      }
    }
  }
`;
