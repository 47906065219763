import axios from "axios";

export const getInvoices = () => {
  return function (dispatch, getState) {
    const { lang } = getState().lang;
    axios
      .post(process.env.REACT_APP_API_URL, {
          lang: lang,
          base: "users",
          function: "invoice_list",
          pars: { customerid: localStorage.getItem("userId") },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        console.log(res.data)
        dispatch({type:"GET_DATA", payload: res.data.data})
      });
  };
};

const initialState = {
  data: [],
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA":
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
