import axios from 'axios'

export const fetchPaymentsData = () => {
    return function (dispatch) {
        axios
          .post(process.env.REACT_APP_API_URL, {
            lang: "ka",
            base: "users",
            function: "list_payments",
            pars: { account_id: localStorage.getItem("userId") },
            apikey: process.env.REACT_APP_API_KEY,
          })
          .then((res) => {
            dispatch({ type: "FETCH_PAYMENTS_DATA", payload: res.data.data });
          });
      };
}

const initialState = {
    data: []
}
export const paymentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PAYMENTS_DATA":
        return {data: action.payload}
        default:
            return state
    }
}