import axios from "axios";

// Actions
export const userSuccess = (services) => {
  return {
    type: "SERVICES_REQ",
    payload: services,
  };
};

export const fetchUserServices = (prop) => {
  return function (dispatch,getState) {
    const userId = localStorage.getItem("userId");
    const {lang} = getState().lang
    userId && axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        lang: lang,
        function: "product_list",
        pars: { account_id: userId, token:  localStorage.getItem("token")},
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        const services = res.data.data;
        dispatch(userSuccess(services));
      })
  };
};

// State
const initialState = {
  services: [],
};

// Reducer
export const userServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SERVICES_REQ":
      return { services: action.payload };
    default:
      return state;
  }
};
