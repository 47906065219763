import axios from "axios";

export const getContracts = (type) => {
  return function (dispatch, getState) {
      console.log(type)
    const { lang } = getState().lang;
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: lang,
        base: "users",
        function: "list_user_docs",
        pars: {
          account_id: localStorage.getItem("userId"),
          token:localStorage.getItem("token"),type},
        apikey: "u4AzRTE7Mpp6R4aEzopI",
      })
      .then((res) => {
        console.log(res.data);
        dispatch({ type: "GET_DATA", payload: res.data.data });
      });
  };
};

const initialState = {
  data: [],
};

export const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA":
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
