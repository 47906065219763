
import React, {useEffect} from "react";
import {useDispatch} from 'react-redux'
import {fetchipTvData} from '../redux/reducers/landing/iptvReducer'

// components
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import Slider from "../Lcomponents/Slider/Slider";
import Double from "../Lcomponents/Television/Double";
import DoubleSearch from "../Lcomponents/Television/DoubleSearch";
import Description from "../Lcomponents/Television/Description";

const Television = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchipTvData())
  }, [])
  return (
    <div>
      <Navbar />
      <Menu />
      <Slider />
      <DoubleSearch />
      <Double />
      <Description />
      <Footer />
    </div>
  );
};

export default Television;
