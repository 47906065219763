import styled from "styled-components";
export const StyledContainer = styled.div`
  width: ${(p) => (p.categoryIsNotNull ? "300px" : "300px")};
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: ${(p) => (p.categoryIsNotNull ? "0px" : "0px")};;
  height:${(p) => (p.categoryIsNotNull ? "200px" : "100%")};;
  transition: 300ms;
  @media (max-width:768px) {
    height:100%;
    margin:0;
    padding:0;
    flex-wrap:wrap;
  }
`;
export const StyledCategory = styled.div`
  animation: 2s ${(p) => p.bounceAnimation};
  width: ${(p) => (p.categoryIsNotNull ? "100px" : "100px")};
  height: ${(p) => (p.categoryIsNotNull ? "100px" : "100px")};
  margin-top:15px;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: 300ms;
  position: relative;
  overflow: hidden;
  @media (max-width:768px) {
    margin:0;
    padding:0;
    margin-top:20px;
  }
  .logo {
    display: flex;
    align-items: center;
    ${(p) => p.category !== null && p.category !== p.seted && "opacity:0.1;"};
    opacity:0.4;
    ${(p) => p.category === p.seted && "opacity:1;"};
    &:last-child {
      margin-left: 0;
    }
    .cub {
      width: 10px;
      height: 10px;
      background-color: #eb0028;
      margin-left: 4px;
      z-index: 11;
      transition: 300ms;
      ${(p) => p.category === p.seted && "background-color:#eb0028;"};
    }
  }

  h4 {
    font-size: ${(p) => (p.categoryIsNotNull ? "10px" : "10px")};
    font-family: "BOG 2017 Headline";
    color: #004592;
    margin-top: 8px;
    transition: 300ms;
    z-index: 11;
    ${(p) => p.category !== null && p.category !== p.seted && "opacity:0.1;"};
    opacity:0.4;
    ${(p) => p.category === p.seted && "opacity:1;"};
  }
  &:hover {
      h4 {
          opacity:1;
      }
      .logo {
          opacity:1;
      }
  }
`;

export const StyledTitle = styled.div`
  h3 {
    font-family: "BOG 2017 Headline";
    text-align: center;
    color: #004592;
    margin-top: 30px;
  }
`;


export const StyledLogos = styled.div`
  display:flex;
  justify-content:space-around;
  align-items:center;
  width:800px;
  max-width:100%;
  flex-wrap:wrap;
  margin:0 auto;
  .logo {
    height:100px;
    display:flex;
  justify-content:center;
  align-items:center;
  }
  img {
    width:120px;

  }
  `

  export const StyledAmericanCard = styled.div`
      display:flex;
  justify-content:flex-start;
  align-items:center;
  width:645px;
  max-width:100%;
  flex-wrap:wrap;
  margin:0 auto;
  h4 {
    font-size:10px;
    margin-left: 10px;
    margin-top:5px;
  }
  `

  export const Styledrequisite = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  box-shadow: 0px 0px 5px #e6e6e6;
  margin-top:0px;

  height:130px;
  margin:10px;
  padding:10px;
  text-align:center;
  @media (max-width:768px) {
    margin-top:30px;
  }
  h3 {
    font-size:14px;
    margin-top:5px;
    text-align:left;
    padding:0;
    margin:0;
    margin-top:5px !important; 
    font-size:12px;
  }

  h4 {
    font-size:14px;
    margin-top:5px;
    padding:0;
    margin:0;
    font-size:12px;

  }
  .tbc {
    color:#00AEEF;
  }
  .geo {
    color:#FC691C;
  }
    img {
      width:150px;
      height:28px;
    }
    span {
      font-size:12px;
    }
  `