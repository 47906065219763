import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import { DatePicker, Table } from "antd";
import { useTranslation } from "react-i18next";
import {getContracts} from '@Redux/reducers/dashboard/contract'
import "moment/locale/ka";

import {
  StyledBar,
  StyledPickerContainer,
  StyledButton,
  StyledTableContainer,
} from "./Styles/InvoiceStyle";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "ინვოისი",
    dataIndex: "description",
  },
  {
    title: "თარიღი / დრო",
    dataIndex: "create_date",
  },
  {
    title: "",
    dataIndex: "link",
    key: "x",
    render: (link) => <a href={link}>გახსნა</a>,
  },
];

const Invoice = () => {
  const dispatch = useDispatch();
  const {data} = useSelector((state) => state.contracts)

  const { t, i18n } = useTranslation();
  const locale = {
    lang: {
      locale: t("RangePicker"),
      placeholder: "აირჩიეთ თარიღი",
      yearFormat: "YYYY",
      dateFormat: "D M YYYY",
      dayFormat: "D",
      dateTimeFormat: "D M YYYY HH:mm:ss",
    },
  };
  useEffect(() => {
    const nodes = document.querySelectorAll(".ant-picker-input input");
    nodes[0].placeholder = t("RangePickerPlaceholder0");
    nodes[nodes.length - 1].placeholder = t("RangePickerPlaceholder1");
    dispatch(getContracts("invoice"))
  }, [localStorage.getItem("switched")]);
  return (
    <div>
      <StyledBar>
        <StyledPickerContainer>
          <div className="picker__box">
            <RangePicker locale={locale} />
          </div>
        </StyledPickerContainer>
        <div>
          <StyledButton large={true}>დადასტურება</StyledButton>
        </div>
      </StyledBar>
      <StyledTableContainer>
        <Table columns={columns} dataSource={data} size="middle" rowKey="id" />
      </StyledTableContainer>
    </div>
  );
};

export default Invoice;
