import React, { useState } from "react";
// packages
import { zoomInDown } from "react-animations";
import { keyframes } from "styled-components";
import { message } from "antd";
import { useTranslation } from "react-i18next";

// components
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

// assets
import tbclogo from "../../assets/png/tbcpaylogo.png";
import oppalogo from "../../assets/png/oppalogo.png";
import mastercardlogo from "../../assets/png/mastercardlogo.png";
import epaylogo from "../../assets/png/epaologo.png";
import emoneylogo from "../../assets/png/emoneylogo.png";
import tbc from "../../assets/png/tbc.png";
import georgianbank from "../../assets/png/georgianbank.png";

// styles
import {
  StyledCategory,
  StyledContainer,
  StyledTitle,
  StyledLogos,
  StyledAmericanCard,
  Styledrequisite,
} from "./styles/CheckBalanceStyles";

// anim
const bounceAnimation = keyframes`${zoomInDown}`;

const CheckBalance = () => {
  const [category, setCategory] = useState(null);
  // detect
  const categoryIsNotNull = category !== null;

  // category handler
  const setCategoryHandler = (prop, categoryText) => {
    setCategory(prop);
    message.success(`${t("page_title.balance.selected")}: ${categoryText}`);
  };

  const { t } = useTranslation();

  return (
    <div>
      <StyledTitle>
        <h3>{t("page_title.balance.title1")}</h3>
      </StyledTitle>
      <StyledContainer categoryIsNotNull={categoryIsNotNull}>
        <StyledCategory
          bounceAnimation={bounceAnimation}
          categoryIsNotNull={categoryIsNotNull}
          category={category}
          seted={1}
          onClick={() => setCategoryHandler(1, t("page_title.balance.round1"))}
        >
          <div className="logo">
            <div className="cub"></div>
            <div className="cub"></div>
            <div className="cub"></div>
          </div>
          <h4>{t("page_title.balance.round1")}</h4>
        </StyledCategory>
      {/**
        <StyledCategory
          bounceAnimation={bounceAnimation}
          categoryIsNotNull={categoryIsNotNull}
          category={category}
          seted={2}
          onClick={() => setCategoryHandler(2, t("page_title.balance.round2"))}
        >
          <div className="logo">
            <div className="cub"></div>
            <div className="cub"></div>
            <div className="cub"></div>
          </div>
          <h4>{t("page_title.balance.round2")}</h4>
        </StyledCategory> */}

        <StyledCategory
          bounceAnimation={bounceAnimation}
          categoryIsNotNull={categoryIsNotNull}
          category={category}
          seted={3}
          onClick={() => setCategoryHandler(3, t("page_title.balance.round3"))}
        >
          <div className="logo">
            <div className="cub"></div>
            <div className="cub"></div>
            <div className="cub"></div>
          </div>
          <h4>{t("page_title.balance.round3")}</h4>
        </StyledCategory>
      </StyledContainer>
      {category === 1 ? (
        <StepOne />
      ) : category === 2 ? (
        <StepTwo />
      ) : category === 3 ? (
        <StepThree />
      ) : null}
      {category !== null && (
        <>
          <StyledAmericanCard>
            <img style={{ width: 150 }} src={mastercardlogo} alt="" />

            <h4
              style={{ textAlign: "center", fontFamily: "BOG 2017 Headline" }}
            >
              {t("page_title.balance.plastic")}
            </h4>
          </StyledAmericanCard>
        </>
      )}

      <StyledTitle>
        <h3>{t("page_title.balance.title2")}</h3>
      </StyledTitle>

      <StyledLogos>
        <div className="logo">
          <img src={tbclogo} alt="" />
        </div>

        <div className="logo">
          <img src={oppalogo} alt="" />
        </div>

        <div className="logo">
          <img src={epaylogo} alt="" />
        </div>

        <div className="logo">
          <img src={emoneylogo} alt="" />
        </div>
      </StyledLogos>

      <div>
        <StyledTitle>
          <h3>{t("page_title.balance.title3")}</h3>
        </StyledTitle>
        <StyledContainer style={{ marginBottom: "50px" }}>
          <Styledrequisite>
            <img src={tbc} alt="" />
            <h3>{t("page_title.balance.bank1")}</h3>
            <h4>{t("page_title.balance.bankCode")}: TBCBGE22</h4>
            <h4>
            {t("page_title.balance.beneficiary")}:{" "}
              <span className="tbc">GE80TB7698236070100001</span>
            </h4>
          </Styledrequisite>

          <Styledrequisite>
            <img src={georgianbank} alt="" />
            <h3> {t("page_title.balance.bank2")}</h3>
            <h4> {t("page_title.balance.bankCode")}: BAGAGE22</h4>
            <h4>
            {t("page_title.balance.beneficiary")}:{" "}
              <span className="geo">GE67BG0000000162411457</span>
            </h4>
          </Styledrequisite>
        </StyledContainer>
      </div>
    </div>
  );
};

export default CheckBalance;
