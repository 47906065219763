import styled from "styled-components";
export const StyledSelectNumber = styled.a`
  font-family: "BOG 2018";
  color: rgb(0, 69, 146);
  font-size: 12px;
`;

export const StyledPacketInfoButton = styled.button`
  padding: 4px;
  font-size:12px;
  background:transparent;
  border:0;
  display:flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  p {
    margin-top:5px;
  }
  svg {
    font-size:25px;
  

  }
`;

export const StyledInfoList = styled.ul`
position: relative;
display:flex;
 justify-content: flex-start;
 align-items: flex-start;
 flex-direction: column;
.logo {
  width:100px;
  margin-bottom: 20px;
}
  li {
    list-style:none;
    display:flex;
    justify-content:space-between;
    align-items:center;
    font-size:12px;
    width:100%;
    margin-top:8px;
    box-shadow: 0px 0px 3px 0px rgba(204, 204, 204, 1);
    border-radius:3px;
    padding:8px;
  }
`


export const StyledWrapper = styled.div`
  width: 1200px;
  margin: 0 auto;
  max-width:100%;
  display:flex;
  flex-direction:column;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top:30px;
  .controll_table {
    background-color:#F2F2F2;
  }
  .title {
    display:flex;
    justify-content:center;
    align-items:center;
    padding-bottom:15px;

    h1 {
      font-family: "BOG 2017 Headline";
      font-size: 16px;
      color: #7f848a;
      text-align: center;
      color:#0e4c90;
    }
    p {
      font-size: 13px;
      text-align: center;
      color: #282828;
    
    }
    img {
      width:100px;
    }
    
  }
`;
export const StyledTariff = styled.div`
  width: 230px;
  height: 280px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 0px rgba(204, 204, 204, 1);
  transition: 300ms;
  background-color: #fff;
  transition: 300ms;
  @media (max-width:995px) {
    margin-top:15px;
  }
  .price {
    background: rgb(63,121,186);
background: linear-gradient(90deg, rgba(63,121,186,1) 0%, rgba(0,69,146,1) 100%);
    width: 100%;
    height:50px;
    padding: 2px;
    padding-top:5px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    display:flex;
    justify-content:center;
    align-items:center;
    span {
      font-size:12px;
      }
    h3 {
      font-family: "BOG 2017 Headline";
      font-size: 26px;
      color:#fff;
      position:relative;
      span {
        position:absolute;
        right:-13px;
        top:7px;
        font-family: "BOG 2018";
      }
    }
    h4 {
      display:flex;
      flex-direction:column;
      margin-top:7px;
      margin-left:3px;
      color:#fff;
      font-size:12px;
    }
  }
  &:hover {
    z-index: 111;
    transform: scale(1.04);
  }
  .head {
    width: 100%;
    text-align: center;
    font-family: "BOG 2017 Headline";
    color: #282828;
    padding: 5px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0;
    }
    h1 {
      font-size: 30px;
      color: #004592;
    }
    h3 {
      color: #282828;
      font-size: 16px;
      margin-top:20px;
    }
    h4 {
      color: #969696;
    }
  }
  ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: #f5f5f5 1px solid;
    span {
      font-size: 12px;
      color: #7f848a;
    }
  }
  .order__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;


export const StyledOrderButton = styled.button`
  padding: 10px;
  width: 155px;
  border: 0;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  color: #282828;
  border-radius: 3px;
  cursor: pointer;
  transition: 300s;
  border: 1px solid #004592;
  background: transparent;
  margin-top: 5px;
  transition: 300ms;
  &:hover {
    border: 1px solid rgba(253,97,124,1);
    background: linear-gradient(90deg, rgba(253,97,124,1) 0%, rgba(235,0,41,1) 100%);
    color:#fff;
  }
`;

export const StyledTariffContainer = styled.div`
  display: flex;
`;

export const StyledTarrifWrapper = styled.div`
  width:1100px;
  max-width:100%;
margin:0 auto;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  margin-top:20px;
  .tariff__container {
    display:flex;
    justify-content:space-around;
    align-items:center;
    width:100%;
    @media (max-width:1000px) {
      flex-direction:column;
    }
  }
  .section__title {
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    h1 {
      font-family: "BOG 2017 Headline";
      font-size: 16px;
      color: #7f848a;
      text-align: center;
      padding-top: 15px;
      padding-bottom:25px;
      color:#0e4c90;
    }
  }

`
export const StyledNumberItem = styled.div`
  width:200px;
  height:130px;
  box-shadow: 0px 0px 4px 0px rgba(204, 204, 204, 1);
  margin:10px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  h4 {
    font-family: "BOG 2017 Headline";
  font-size: 12px;
  color:#282828;
  }
  h5 {
    font-family: "BOG 2018";
  font-size: 12px;
  }
  button {
    width:100%;
    background:#fff;
    box-shadow: 0px 0px 2px 0px rgba(204, 204, 204, 1);
    font-family: "BOG 2017 Headline";
    border:0;
    border-radius:3px;
    margin-top:5px;

    a {
      color:#0e4c90;
      font-size:11px;
    }
  }
  
`

export const StyledControllers = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  button {
    margin:10px;
    background-color:#0e4c90;
    color:#fff;
    width:30px;
    height:30px;
    border-radius:50%;
    border:0;
    transition:300ms;
    &:hover {
      opacity:0.5;
    }
    &:focus {
      outline:none;
    }
  }
`

export const StyledTableContainer = styled.div`
overflow:hidden;
overflow-y:scroll;
height:200px;
width:980px;
max-width:100%;
margin:0 auto;
border:1px solid #ccc;
border-radius:3px;
  table {
    border-collapse: collapse;
  width: 100%;
  }
  th {
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #7f848a;
    height:30px;
    padding:5px;
  }
  td {
    height:35px;
    font-size:12px;
    border-bottom:1px solid #ccc;
    font-family: "BOG 2018";
    padding:5px;
  }
  span {
    font-family: "BOG 2018";
    font-size:12px;
  }
  
`;