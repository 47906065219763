import React, { useState, useEffect } from "react";
import { useDispatch, useSelector} from 'react-redux'
import {getContracts} from '@Redux/reducers/dashboard/contract'
import { StyledContainer } from "./Styles/DocumentsStyle";
import { Table } from "antd";

const columns = [
  {
    title: "ხელშეკრულება",
    dataIndex: "description",
  },
  {
    title: "თარიღი / დრო",
    dataIndex: "create_date",
  },
  {
    title: "",
    dataIndex: "link",
    key: "x",
    render: (link) => <a href={link}>გახსნა</a>,
  },
];

const Documents = () => {
  const dispatch = useDispatch();
  const {data} = useSelector((state) => state.contracts)

useEffect(() => {
  dispatch(getContracts("agreement"))
  console.log(data)
}, [])
  return (
    <StyledContainer>
      <Table columns={columns} dataSource={data} size="middle" />
    </StyledContainer>
  );
};

export default Documents;
