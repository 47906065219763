import React from "react";
// components
import AddressItem from "./AddressItem";
import AddressCreate from "./AddressCreate";
// styles
import { StyledContainer } from "./styles/OptionsStyle";

const Address = () => {
  return (
    <>
      <AddressCreate />
      <StyledContainer>
        <AddressItem />
      </StyledContainer>
    </>
  );
};

export default Address;
