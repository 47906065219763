import React, { useState, useEffect } from "react";

import {useDispatch, useSelector} from 'react-redux'
import { DatePicker, Select, Table } from "antd";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {fetchPaymentsData} from '../../../../../redux/reducers/dashboard/payments'
import "moment/locale/ka";

import {
  StyledBar,
  StyledButton,
  StyledPickerContainer,
  StyledTableContainer,
  StyledPaginationContainer,
} from "./Styles/PaymentsStyle";

const { RangePicker } = DatePicker;
const { Option } = Select;

const columns = [
  {
    title: "გადახდის თარიღი",
    dataIndex: "payment_date",
  },
  {
    title: "გადახდის მეთოდი",
    dataIndex: "notes",
  },
  {
    title: "პაკეტი",
    dataIndex: "p_display_name",
  },
  {
    title: "ღირებულება",
    dataIndex: "ammount",
    render: (prop) => <span>{prop} ₾</span>
  },
];
const Payments = () => {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.payments.data)
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const { t } = useTranslation();

  const locale = {
    lang: {
      locale: t("RangePicker"),
      placeholder: "აირჩიეთ თარიღი",
      yearFormat: "YYYY",
      dateFormat: "D M YYYY",
      dayFormat: "D",
      dateTimeFormat: "D M YYYY HH:mm:ss",
    },
  };

  useEffect(() => {
    const nodes = document.querySelectorAll(".ant-picker-input input");
    nodes[0].placeholder = t("RangePickerPlaceholder0");
    nodes[nodes.length - 1].placeholder = t("RangePickerPlaceholder1");
    dispatch(fetchPaymentsData())
    
  }, [localStorage.getItem("switched")]);
  return (
    <div>
      <StyledBar>
        <StyledPickerContainer>
          <div className="picker__box">
            <Select
              defaultValue="სერვისის არჩევა"
              onChange={handleChange}
              style={{ width: "150px" }}
            >
              <Option value="595607900" style={{ fontSize: "12px" }}>
                სტანდარტი
              </Option>
              <Option value="577584877" style={{ fontSize: "12px" }}>
                ულტრა
              </Option>
            </Select>
          </div>
          <div className="picker__box">
            <RangePicker locale={locale} />
          </div>
        </StyledPickerContainer>
        <div>
          <StyledButton large={true}>დადასტურება</StyledButton>
        </div>
      </StyledBar>
      <StyledTableContainer>
        <Table columns={columns} dataSource={data} size="middle" />
      </StyledTableContainer>
      <StyledPaginationContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <h4>სულ გადახდილი თანხა: 0.085 ₾</h4>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: "10px",
            }}
          >
            <h5 style={{ marginTop: "7px", paddingRight: "5px" }}> ექსპორტი</h5>
            <h5
              style={{
                textAlign: "center",
              }}
            >
              <FilePdfOutlined
                style={{ fontSize: "27px", color: "#E1191E", marginTop: "7px" }}
              />
            </h5>
            <h5 style={{ marginLeft: "5px", textAlign: "center" }}>
              <FileExcelOutlined
                style={{ fontSize: "27px", color: "#02723B", marginTop: "7px" }}
              />
            </h5>
          </div>
        </div>
      </StyledPaginationContainer>
    </div>
  );
};

export default Payments;
