export const switchLangAction = (lang) => {
    console.log(lang)
    return {type:"SWITCH_LANG", payload:lang}
}
const initialState = {
    lang: localStorage.getItem("switched") ? "en" : "ka"
}
export const langReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SWITCH_LANG":
        return {lang: action.payload}
        default:
            return state
    }
}