import React, { useEffect, useState } from "react";

//packages
import { Select, Button, message } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// reducers
import { setPhone } from "../../redux/reducers/landing/basketReducer";
import { numberCreate } from "../../redux/reducers/dashboard/userOrders";
import {
  fetchTelephoneNumbersFilterByPrice,
  fetchTelephoneNumbersPrice,
  fetchTelephoneNumbersFilterByCountry,
  fetchCountryList,
} from "../../redux/reducers/common/numbersReducer";

// styles
import {
  StyledContainer,
  StyledImg
} from "./styles/FilterStyles";

const { Option } = Select;

const Filter = () => {
  const { countryList, telephoneNumbersPrice } = useSelector(
    (state) => state.telephoneNumbers
  );
  const [showCheck, setShowCheck] = useState(false);
  const [showNumberInfo, setShowNumberInfo] = useState(false);
  const [telephoneItem, setTelephoneItem] = useState(null);
  const [country, setCountry] = useState("");
  const [price, setPrice] = useState(null);
  const { t } = useTranslation();

  // ტელეფონის ნომრები
  const { telephoneNumbers } = useSelector((state) => state.telephoneNumbers);

  const dispatch = useDispatch();

  // ნომრების გაფილტვრა ფასის მიხედვით
  const numbersFilterByPrice = (value) => {
    setPrice(value);
    dispatch(fetchTelephoneNumbersFilterByPrice(value));
    setTelephoneItem(null);
  };
  // ნომრების გაფილტვრა ქალაქების მიხედვით
  const countryFilter = (value, prop) => {
    setCountry(prop.prop);
    dispatch(fetchTelephoneNumbersFilterByCountry(value));
    setPrice(null);
    setTelephoneItem(null);
  };

  const phoneNumberHandler = (prop) => {
    console.log(prop)
    setTelephoneItem(prop.prop);
    dispatch(numberCreate(prop.prop))
    setPrice(prop.prop.buy_cost)
  };

  const selectNumber = () => {
    if (telephoneItem !== null) {
      dispatch(setPhone(telephoneItem));
      message.success(
        t("page_title.voip.successNumberSelect")
      );
      setShowCheck(true);
      setShowNumberInfo(true);
    } else {
      message.error(t("page_title.voip.selectNumber"));
    }
  };

  useEffect(() => {
    dispatch(fetchCountryList());
    dispatch(fetchTelephoneNumbersPrice());
    showCheck &&
      setTimeout(() => {
        setShowCheck(false);
      }, 1900);
  }, [showCheck]);
  return (
    <>
      <StyledContainer>
        <StyledImg show={showCheck}>
          <img src="https://i.gifer.com/origin/11/1184b4c0aa977f925dde58d2075772dd.gif" />
        </StyledImg>

        <div className="sec">
          <h5>{t("page_title.voip.region")}</h5>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="ქალაქი"
            defaultValue={t("page_title.voip.optionTbilisi")}
            onChange={(value, prop) => countryFilter(value, prop)}
          >
            {countryList?.map((item) => (
              <Option
                key={item.id}
                value={item.area_code}
                prop={item.area_name}
              >
                {item.area_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="sec">
          <h5>{t("page_title.voip.price")}</h5>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={t("page_title.voip.all")}
            value={!country ? price : price}
            onChange={(value) => numbersFilterByPrice(value)}
          >
            <Option>{t("page_title.voip.all")}</Option>
            {telephoneNumbersPrice?.map((item) => (
              <Option key={item.id} value={item.price}>
                {t("page_title.voip.price")}: {item.price}₾
              </Option>
            ))}
          </Select>
        </div>

        <div className="sec">
          <h5>{t("page_title.voip.number")}</h5>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={t("page_title.voip.number")}
            value={!price ? telephoneItem?.number : telephoneItem?.number}
            onChange={(value, prop) => phoneNumberHandler(prop)}
          >
            {telephoneNumbers?.map((item) => (
              <Option key={item.id} value={item.number} prop={item}>
                {item.number}
              </Option>
            ))}
          </Select>
        </div>
        <div className="sec">
          <h5></h5>
          <Button className="select_btn" onClick={() => selectNumber()}>
            <a href="#tariff">{t("page_title.voip.select")}</a>
          </Button>
        </div>
      </StyledContainer>
    </>
  );
};

export default Filter;
