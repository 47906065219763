import {combineReducers} from 'redux'

import {userInfoReducer} from './landing/userInfoReducer'
import {basketReducer} from './landing/basketReducer'
import {alphabetReducer} from './landing/alphabetReducer'
import {tariffDataReducer} from './landing/tariffReducer'
import {a2pReducer} from './landing/a2pReducer'
import {internetReducer} from './landing/internetReducer'
import {iptvReducer} from './landing/iptvReducer'
import {pbxReducer} from './landing/pbxReducer'
import {userOrdersReducer} from './dashboard/userOrders'
import {userServicesReducer} from './dashboard/userServices'
import {numbersReducer} from './common/numbersReducer'
import {langReducer} from './common/langReducer'
import {userAddressReducer} from './dashboard/userCreateAddress'
import {serviceContentReducer} from './dashboard/servicesContentMappings'
import {sipNumberOption} from './dashboard/sipNumberOption'
import {extractionReducer} from './dashboard/extraction'
import {smsReducer} from './dashboard/sms'
import {userContactReducer} from './dashboard/userContacts'
import {paymentsReducer} from './dashboard/payments'
import {invoiceReducer} from './dashboard/invoice'
import {contractReducer} from './dashboard/contract'
import {toppingBallanceReducer} from './landing/toppingBallance'


export const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    basket: basketReducer,
    userOrders: userOrdersReducer,
    telephoneNumbers: numbersReducer,
    tariff: tariffDataReducer,
    userServices: userServicesReducer,
    alphabet: alphabetReducer,
    internet: internetReducer,
    iptv: iptvReducer,
    pbx: pbxReducer,
    userAddress: userAddressReducer,
    a2p: a2pReducer,
    servicesContentMappings: serviceContentReducer,
    sipNumberOption: sipNumberOption,
    extraction:extractionReducer,
    sms: smsReducer,
    userContacts: userContactReducer,
    lang: langReducer,
    payments: paymentsReducer,
    invoice: invoiceReducer,
    contracts: contractReducer,
    toppingBallance: toppingBallanceReducer
})