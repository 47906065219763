import React from "react";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";

import { StyledContainer } from "./styles/RulesStyle";
const { Panel } = Collapse;

const Rules = () => {

  const { t } = useTranslation();

  return (
    <StyledContainer>
      <div>
        <h3 className="title">{t("faq.rules.title")}</h3>
      </div>
      <Collapse defaultActiveKey={["1"]} ghost accordion>
        <Panel header={t("faq.rules.1.title")} key="1">
          <p>
          {t("faq.rules.1.1")}
          </p>
        </Panel>
        <Panel header={t("faq.rules.2.title")} key="2">
          <p>
          {t("faq.rules.2.1")}
          </p>
        </Panel>
        <Panel header={t("faq.rules.3.title")} key="3">
          <p>
          {t("faq.rules.3.1")}
          </p>
        </Panel>
        <Panel header={t("faq.rules.4.title")} key="4">
          <p>
          {t("faq.rules.4.1")}
          </p>
        </Panel>
        <Panel header={t("faq.rules.5.title")} key="5">
          <p>
          {t("faq.rules.5.1")}
          </p>
        </Panel>
        <Panel header={t("faq.rules.6.title")} key="6">
          <p>
          {t("faq.rules.6.1")}
          </p>
        </Panel>
      </Collapse>
    </StyledContainer>
  );
};

export default Rules;
