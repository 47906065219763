import React from "react";

import { Radio, Select } from "antd";

import {
  StyledFormGroup,
  StyledLabel,
  StyledInput,
  StyledShowPassword,
  StyledBirthDateContainer,
  StyledBirthDayInput,
} from "./LoginFormStyles";

import Timer from '../../Timer/Timer'

const { Option } = Select;
const plainOptions = ["ფიზიკური პირი", "იურიდიული პირი"];
const pinOptions = ["მობილურის ნომერი", "ელ.ფოსტა"];

export const getSteps = ({
  state,
  legalForm,
  radioHandler,
  radio,
  handleRegisterChange,
  onChange,
  legalFormSelected,
  showPasswordHandler,
  showPassword,
  onHandleNumberChange,
  showTimer,
  showTimerHandler,
  pinNotification,
  forgotPassword,
  disableTimer,
  t,
  pinCodeHandler,
  firstNameGeo,
  setFirstNameGeo,
  lastNameGeo,
  setLastNameGeo,
  checkInfo,
  registerPhoneNumber,
  registerEmail,
  forgotPasswordPhoneNumber,
  setApiPinCodeFnc,
  setNickNameGeo,
  nickNameGeo,
  legalUser,
  currentUser
}) => [
  {
    title: "",
    content: (
      <div className="step__box radio__buttons">
        <StyledFormGroup>
          <Radio.Group
            options={plainOptions}
            onChange={radioHandler}
            value={radio.value1}
            style={{ color: "red" }}
          />
        </StyledFormGroup>
      </div>
    ),
  },
  {
    title: "",
    content: (
      <div className="step__box">
        <StyledFormGroup>
          <StyledLabel>
            {legalForm === 1 ? "პირადი ნომერი" : "საიდენტიფიკაციო კოდი"}
          </StyledLabel>
          <StyledInput
            name={legalForm === 1 ? "personalNumber" : "identificationCode"}
            isValid={
              legalForm === 1
                ? state.error.personalNumber
                : state.error.identificationCode
            }
            placeholder={
              legalForm === 1
                ? "შეიყვანეთ პირადი ნომერი"
                : "შეიყვანეთ საიდენტიფიკაციო კოდი"
            }
            onChange={onHandleNumberChange}
            value={
              legalForm === 1
                ? state.registerForm.personalNumber
                : state.registerForm.identificationCode
            }
          />
          {checkInfo.personalNumber && (
            <p style={{ fontSize: "12px", color: "#eb0028" }}>
              პირადი ნომერი დაკავებულია
            </p>
          )}
                {checkInfo.identificationCode && (
            <p style={{ fontSize: "12px", color: "#eb0028" }}>
              საიდენტიფიკაციო კოდი დაკავებულია
            </p>
          )}
        </StyledFormGroup>
        {legalForm === 1 ? (
          ""
        ) : (
          <StyledFormGroup isValid={!state.error.legalFormSelected}>
            <StyledLabel>სამართლებრივი ფორმა</StyledLabel>
            <Select
              placeholder="აირჩიეთ სასურველი ფორმა"
              defaultValue={
                state.registerForm.legalFormSelected.length
                  ? state.registerForm.legalFormSelected
                  : "აირჩიეთ სასურველი ფორმა"
              }
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option
                value={t("LegalFormOptions.1")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.1")}
              </Option>
              <Option
                value={t("LegalFormOptions.2")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.2")}
              </Option>
              <Option
                value={t("LegalFormOptions.3")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.3")}
              </Option>
              <Option
                value={t("LegalFormOptions.4")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.4")}
              </Option>
              <Option
                value={t("LegalFormOptions.5")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.5")}
              </Option>
              <Option
                value={t("LegalFormOptions.6")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.6")}
              </Option>
              <Option
                value={t("LegalFormOptions.7")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.7")}{" "}
              </Option>
              <Option
                value={t("LegalFormOptions.8")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.8")}
              </Option>
              <Option
                value={t("LegalFormOptions.9")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.9")}{" "}
              </Option>
              <Option
                value={t("LegalFormOptions.10")}
                style={{ fontSize: "12px" }}
              >
                {t("LegalFormOptions.10")}
              </Option>
            </Select>
          </StyledFormGroup>
        )}
        <StyledFormGroup>
          <StyledLabel>{legalForm === 1 ? "სახელი" : "დასახელება"}</StyledLabel>
          <StyledInput
            name={legalForm === 1 ? "firstname" : "lls"}
            isValid={legalForm === 1 ? state.error.firstname : state.error.lls}
            placeholder={
              legalForm === 1 ? "შეიყვანეთ სახელი" : "შეიყვანეთ დასახელება"
            }
            onChange={legalForm === 1 ? setFirstNameGeo : handleRegisterChange}
            value={legalForm === 1 ? firstNameGeo : state.registerForm.lls}
          />
        </StyledFormGroup>
        {legalForm === 1 && (
          <StyledFormGroup>
            <StyledLabel>გვარი</StyledLabel>
            <StyledInput
              name="lastname"
              isValid={state.error.lastname}
              placeholder="შეიყვანეთ გვარი"
              onChange={setLastNameGeo}
              value={lastNameGeo}
            />
          </StyledFormGroup>
        )}
      </div>
    ),
  },
  {
    title: "",
    content: (
      <div className="step__box">
        {legalForm === 2 && (
          <StyledFormGroup>
            <StyledLabel>პასუხისმგებელი პირი</StyledLabel>
            <StyledInput
              name="responsiblePerson"
              isValid={state.error.responsiblePerson}
              placeholder="პასუხისმგებელი პირის სახელი,გვარი"
              onChange={handleRegisterChange}
              value={state.registerForm.responsiblePerson}
            />
          </StyledFormGroup>
        )}
        <StyledFormGroup>
          <StyledLabel>მომხმარებელი</StyledLabel>
          <StyledInput
            name={legalForm === 1 ? "currentUser" : "legalUser"}
            isValid={
              legalForm === 1 ? state.error.currentUser : state.error.legalUser
            }
            placeholder="შეიყვანეთ მომხმარებლის სახელი"
            onChange={handleRegisterChange}
            value={
              legalForm === 1
                ? state.registerForm.currentUser
                : state.registerForm.legalUser
            }
          />
          {checkInfo.currentUser && (
            <p style={{ fontSize: "12px", color: "#eb0028" }}>
              მომხმარებლის სახელი დაკავებულია
            </p>
          )}
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledLabel>პაროლი</StyledLabel>
          <StyledInput
            name={legalForm === 1 ? "userPassword" : "legalPassword"}
            isValid={
              legalForm === 1
                ? state.error.userPassword
                : state.error.legalPassword
            }
            placeholder="შეიყვანეთ პაროლი"
            onChange={handleRegisterChange}
            value={
              legalForm === 1
                ? state.registerForm.userPassword
                : state.registerForm.legalPassword
            }
            type={showPassword ? "text" : "password"}
          />
          <StyledShowPassword
            type="button"
            onClick={showPasswordHandler}
            showPassword={showPassword}
          >
            <i className="fas fa-eye"></i>
          </StyledShowPassword>
        </StyledFormGroup>
      </div>
    ),
  },
  {
    title: "",
    content: (
      <div className="step__box">
        <StyledFormGroup>
          <StyledLabel>მობილური</StyledLabel>
          <StyledInput
            name="phoneNumber"
            isValid={state.error.phoneNumber}
            placeholder="შეიყვანეთ მობილურის ნომერი"
            onChange={onHandleNumberChange}
            value={state.registerForm.phoneNumber}
            type="text"
            pattern="[0-9]*"
          />
          {checkInfo.phoneNumber && (
            <p style={{ fontSize: "12px", color: "#eb0028" }}>
              მობილური ნომერი დაკავებულია
            </p>
          )}
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledLabel>ელ.ფოსტა</StyledLabel>
          <StyledInput
            name="email"
            isValid={state.error.email}
            placeholder="შეიყვანეთ თქვენი ელ.ფოსტა"
            onChange={handleRegisterChange}
            value={state.registerForm.email}
          />
        </StyledFormGroup>
        {legalForm === 1 && (
          <StyledFormGroup>
            <StyledLabel>დაბადების თარიღი</StyledLabel>
            <StyledBirthDateContainer>
              <StyledBirthDayInput
                isValid={state.error.day}
                value={state.registerForm.day}
                type="text"
                pattern="[0-9]*"
                placeholder="დღე"
                name="day"
                onChange={onHandleNumberChange}
              />
              <StyledBirthDayInput
                isValid={state.error.month}
                value={state.registerForm.month}
                type="text"
                pattern="[0-9]*"
                placeholder="თვე"
                name="month"
                onChange={onHandleNumberChange}
              />
              <StyledBirthDayInput
                isValid={state.error.year}
                value={state.registerForm.year}
                type="text"
                pattern="[0-9]*"
                placeholder="წელი"
                name="year"
                onChange={onHandleNumberChange}
              />
            </StyledBirthDateContainer>
          </StyledFormGroup>
        )}
      </div>
    ),
  },
  {
    title: "",
    content: (
      <div className="step__box">
        <StyledFormGroup>
          <Radio.Group
            options={pinOptions}
            onChange={radioHandler}
            value={radio.value1}
            style={{ color: "red" }}
          />
          <Timer
            forgotPassword={forgotPassword}
            disableTimer={disableTimer}
            showTimer={showTimer}
            showTimerHandler={showTimerHandler}
            pinCodeHandler={pinCodeHandler}
            pinNotification={pinNotification}
            registerPhoneNumber={registerPhoneNumber}
            registerEmail={registerEmail}
            forgotPasswordPhoneNumber={forgotPasswordPhoneNumber}
            setApiPinCodeFnc={setApiPinCodeFnc}
            userName={legalForm === "1" ? currentUser : legalUser}
          />
          
          <div style={{ textAlign: "center" }} className="notification">
            {pinNotification === 1 && (
              <p>
                თქვენ მიიღებთ PIN კოდს ნომერზე:
                <br />
                {state.registerForm.phoneNumber}
              </p>
            )}
            {pinNotification === 2 && (
              <p>
                თქვენ მიიღებთ PIN კოდს ელ.ფოსტაზე:
                <br />
                {state.registerForm.email}
              </p>
            )}
          </div>
        </StyledFormGroup>
      </div>
    ),
  },
];
