import styled from 'styled-components'
export const StyledPinBox = styled.div`
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 5px;
  padding: 1px;
  margin-top: ${(p) => (p.forgotPassword ? "0px" : "20px")};
  margin-bottom: ${(p) => (p.forgotPassword ? "15px" : "20px")};
  width: ${(p) => (p.modal ? "245px" : "245px")};
  background-color: #fff;

  .pin {
    background-color: #00418f;
    color: #fff;
    border: 0;
    padding: 8px;
    border-radius: 5px;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    width: ${(p) => (p.modal ? "150px" : "150px")};
    opacity: ${(p) => (p.disableTimer ? "0.7" : "1")};
    cursor: pointer;
    margin-bottom: 2px;
    margin-left: 2px;
  }
  .pin__input {
    border: 0;
    padding: 0px;
    background-color: transparent;
    width: 72px;
    font-size: 20px;
    margin-left: 5px;
    text-align: center;
    &::placeholder {
      color: #ccc;
    }
    &:focus {
      outline: none;
    }
  }
`;