import axios from "axios";

const initialState = {
  data: [],
};

const a2pDataAction = (data) => {
  return { type: "A2P_DATA", payload: data };
};

export const fetchA2pData = () => {
  return function (dispatch,getState) {
    const {lang} = getState().lang
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: lang,
        base: "info",
        function: "list_pakets",
        pars: { product_category_name: "a2p", user_can_add: 1 },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(a2pDataAction(res.data.products));
      });
  };
};

export const a2pReducer = (state = initialState, action) => {
  switch (action.type) {
    case "A2P_DATA":
      return { ...state, data: action.payload };
    default:
      return state
  }
};
