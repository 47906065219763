import styled from "styled-components";

export const StyledMenuContainer = styled.div`
background: rgb(63,121,186);
background: linear-gradient(90deg, rgba(63,121,186,1) 0%, rgba(0,69,146,1) 100%);
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  position: sticky;
  top: 0;
  z-index: 999;
  height: ${(p) => (p.toggle ? "100%" : "60px")};
  transition: 300ms;
  display:flex;
  justify-content:center;
  align-items:center;
  padding-top:25px;
  .cabinet {
    background-color:#fff;
    padding:8px;
    border-radius:3px;
    a {
      color:#0e4c90;
    }
  }
  .logo {
    padding-bottom:20px;
    img {
      width: 120px;
      opacity: 0;
      transition: 100ms;
    }
    margin-left: -15px;
    @media (max-width: 414px) {
      margin-left: 0;
    }
    @media (max-width:550px) {
        margin-left:8px;
      }
    .active {
      opacity: 1;
    }
  }
  nav {
    width: 1170px;
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media (max-width: 1044px) {
      flex-direction: column;
      width: 100%;
    }
    ul {
      display: flex;
      align-items: center;
     @media (max-width:1044px) {
      flex-direction: column;
      display: ${(p) => (p.toggle ? "flex" : "none")};
     }

      li {
        list-style: none;
        margin-left: 15px;
        @media (max-width: 765px) {
          margin-left: 0;
          margin-top: 15px;
          
        }
        a {
          font-size: 12px;
          color: #fff;
          font-family: "BOG 2017 Headline";
        }
      }
    }
  }
`;

export const StyledToggle = styled.div`
  position: absolute;
  top: 0px;
  right: 15px;
  color: #fff;
  font-size: 20px;
  @media (min-width: 1044px) {
    display: none;
  }
`;


export const StyledAvatar = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;

  img {
    position:absolute;
    height:100%;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#fff;
  border-radius:3px;
  padding:3px;
  margin-left: 25px;
  padding-right:5px;
  position:relative;
  
  @media (max-width: 699px) {
    margin-left: 0px;
  }
  h4 {
    margin-top: -2px;
    margin-left: 15px;
    margin-top: 5px;
    color: #282828;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    @media (max-width: 415px) {
      font-size: 10px;
    }
  }
`;

export const StyledDropdown = styled.ul`
  position:absolute;
  left:0;
  top:45px;
  background-color:#fff;
  border-radius:3px;
  border: 2px solid #f7f7f7;
  display:${p => p.active? "flex !important" : "none !important"};
  flex-direction:column;
  justify-content:flex-start !important;
  align-items:flex-start !important;
  width:180px;
  li {
    width:100%;
    padding-bottom:5px;
    padding-top:5px;
    border-bottom:1px solid #f7f7f7;
    margin-left:0 !important;
    margin-top:0 !important;
    &:last-child {
    border-bottom:0 !important;
  }
  }
  a {
    padding-left:10px !important;
    color:#282828 !important;
    font-size:12px !important;
    font-family: "BOG 2018" !important;
    transition:300ms;
    &:hover {
      color:#004592 !important;
    }
  }
`