import React, { useState, useEffect } from "react";
//packages
import { useDispatch, useSelector } from "react-redux";
import { Modal, Select, Input, Button, message } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
//redux
import {
  fetchUserAddress,
  createUserAddress,
} from "../../redux/reducers/dashboard/userCreateAddress";
import { addOrder } from "../../redux/reducers/dashboard/packetOrder";
import { setServiceContent } from "../../redux/reducers/dashboard/servicesContentMappings";
import { setPhone } from "../../redux/reducers/landing/basketReducer";
//styles
import {
  StyledInputs,
  StyledOrderButton,
  StyledContainer,
} from "./styles/styles";

// json
import city from "./georgiancity.json";

const { Option } = Select;

const OrderAddModal = React.memo(
  ({ order,order_id, category, orderAddModalVisible, handleCancel, handleOk }) => {
    // redux dispatch
    const dispatch = useDispatch();
    // state
    const [address, setAddress] = useState(null);
    const [createdAddressName, setCreatedAddressName] = useState(null);
    const [addressCity, setAddressCity] = useState("");
    const [addresName, setAddresName] = useState("");
    const [addressAddToggle, setAddressAddToggle] = useState(false);
    // redux selectors
    const addressItems = useSelector((state) => state.userAddress.addressItems);
    const phone = useSelector((state) => state.basket.phone);
    // react router dom history
    const history = useHistory();

    // ქალაქის ჩეინჯერი
    function citySelectHandler(value) {
      setAddressCity(value);
    }

    function onSearch(val) {
      console.log("search:", val);
    }

    // მისამართის შექმნის ფუნქცია
    const createAddressHandler = async () => {
      if(!addressCity || !addresName) {
        message.warning("შეიყავნეთ სავალდებულო ველი!")

      } else {
        await dispatch(createUserAddress(addressCity, addresName));
        message.success("ახალი მისამართი წარმატებით დაემატა. გთხოვთ აირჩიოთ მისამართი.")
        dispatch(fetchUserAddress());
        setAddressCity("");
        setAddresName("");
        setAddressAddToggle(false)
      }
    };

    // მისამართის ჩეინჯერი
    function onChange(value,addres_name) {
      setAddress(value);
      setCreatedAddressName(addres_name)
    }

    function onSearch(val) {
      console.log("search:", val);
    }

    // შეკვეთის დამატება
    const addOrderHandler = () => {
      if(address) {
        dispatch(addOrder(address, order,order_id, category, phone,createdAddressName))
        dispatch(setServiceContent(6));
        dispatch(setPhone({}));
        handleCancel();
        message.success("შეკვეთა წარმატებით დაემატა.");
        history.push("/dashboard");
      } else {
        message.success("შეიყვანეთ მისამართი");
      }
      // set service content "ჩემი შეკვეთები"

    };

    useEffect(() => {
      dispatch(fetchUserAddress());
    }, []);

    return (
      <Modal
        title="შეკვეთის მისამართი"
        visible={orderAddModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={570}
        footer={null}
      >
        <div style={{ fontSize: "12px", margin: "5px" }}>
          <span>თქვენი მისამართი</span>
        </div>
        <StyledInputs>
          <Select
            showSearch
            style={{ width: "300px" }}
            placeholder="აირჩიეთ მისამართი"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {addressItems?.map((item) => (
              <Option key={item.id} value={item.id} addres_name={item.address}>
                {item.address}
              </Option>
            ))}
          </Select>
          <Button
            style={{ fontSize: "12px",background:"#0e4c90", color:"#fff" }}
            type="primary"
            onClick={() => setAddressAddToggle(!addressAddToggle)}
            icon={!addressAddToggle ? <PlusOutlined /> : <CloseOutlined />}
          >
            ახალი მისამართის დამატება
          </Button>
        </StyledInputs>
        {addressAddToggle && (
          <>
            {" "}
            <div style={{ fontSize: "12px", margin: "5px" }}>
              <span>ახალი მისამართის დამატება</span>
            </div>
            <StyledContainer>
              <div className="column">
                {" "}
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="აირჩიეთ ქალაქი"
                  optionFilterProp="children"
                  onChange={citySelectHandler}
                  value={addressCity}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {city.map((item) => (
                    <Option key={item.id} value={item.city}>
                      {item.city}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="column">
                {" "}
                <Input
                  onChange={(e) => setAddresName(e.target.value)}
                  value={addresName}
                  placeholder="შეიყვანეთ მისამართი"
                />
              </div>
              <div className="column">
                {" "}
                <Button
                  onClick={createAddressHandler}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  დამატება
                </Button>
              </div>
            </StyledContainer>
          </>
        )}
        <div>
          <StyledOrderButton onClick={addOrderHandler}>
            შეკვეთა
          </StyledOrderButton>
        </div>
      </Modal>
    );
  }
);

export default OrderAddModal;
