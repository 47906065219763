import React from "react";
// packages
import {  Input, message } from "antd";
import { fadeInLeft, fadeInRight } from "react-animations";
import { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  sendNumberOfInternet,
  inputHandler,
} from "@Redux/reducers/landing/toppingBallance";

// styles
import { StyledContainer } from "./styles/StepsStyles";
// anim
const bounceAnimation = keyframes`${fadeInRight}`;
const zoomOutUpAn = keyframes`${fadeInLeft}`;

const StepThree = () => {
  const dispatch = useDispatch();
  const { internet_number, subscriber_data } = useSelector(
    (state) => state.toppingBallance
  );
  const { t } = useTranslation();

  function callback(prop) {
    return message.error(prop);
  }
  return (
    <>
      <StyledContainer bounceAnimation={bounceAnimation} see={false}>
        <div className="step">
          <label>{t("page_title.balance.user")}</label>
          <Input
            style={{ width: 200 }}
            name="internet_number"
            onChange={(e) =>
              dispatch(inputHandler(e.target.value, e.target.name))
            }
          />
        </div>
        <div className="step">
          <label></label>
          <button onClick={() => dispatch(sendNumberOfInternet(callback))}>
            {t("page_title.balance.pastDueBalance")}
          </button>
        </div>
      </StyledContainer>

      <StyledContainer bounceAnimation={zoomOutUpAn} see={true}>
        <div className="step">
          <label>{t("page_title.balance.userName")}</label>
          <Input
            style={{ width: 200 }}
            disabled={true}
            value={subscriber_data ? `${subscriber_data.name}` : ""}
          />
        </div>

        <div className="step">
          <label>{t("page_title.balance.balance")}</label>
          <Input
            style={{ width: 200 }}
            disabled={true}
            value={subscriber_data ? subscriber_data.balance : ""}
          />
        </div>

        <div className="step">
          <label>{t("page_title.balance.totalCost")}</label>
          <Input
            style={{ width: 200 }}
            placeholder={t("page_title.balance.price_holder")}
          />
        </div>

        <div className="step">
          <label></label>
          <button>{t("page_title.balance.pay")}</button>
        </div>
      </StyledContainer>
    </>
  );
};

export default StepThree;
