import React from "react";
import Navbar from "../Lcomponents/Navbar/Navbar";
import Menu from "../Lcomponents/Menu/Menu";
import Footer from "../Lcomponents/Footer/Footer";
import DashboardPage from "../components/Dashboard/DashboardPage";

const Dashboard = () => {
  return (
    <div>
      <div>
        <Navbar />
        <Menu />
      </div>
      <DashboardPage />
      <Footer />
    </div>
  );
};

export default Dashboard;
