import axios from "axios";

// ტარიფების ექშენი

const tarrifData = (data) => {
  return { type: "TARRIF_DATA", payload: data };
};

export const fetchTariffData = () => {
  return function (dispatch,getState) {
    const {lang} = getState().lang
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: lang,
        base: "info",
        function: "list_pakets",
        pars: { product_category_name: "voip", user_can_add: 1, status: 1 },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(tarrifData(res.data.products))
      });
  };
};

const initialState = {
  tariff: [],
};

export const tariffDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TARRIF_DATA":
      return { ...state, tariff: action.payload };
    default:
      return state;
  }
};
