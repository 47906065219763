import React, { useEffect } from "react";
// packages
import { Select, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fadeInLeft, fadeInRight } from "react-animations";
import { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import {
  fetchCityCodes,
  inputHandler,
  sendNumberOfTelephone,
} from "@Redux/reducers/landing/toppingBallance";
// styles
import { StyledContainer } from "./styles/StepsStyles";
// anim
const bounceAnimation = keyframes`${fadeInRight}`;
const zoomOutUpAn = keyframes`${fadeInLeft}`;

const { Option } = Select;

const StepOne = () => {
  const dispatch = useDispatch();
  const { city_codes, costumer_data } = useSelector(
    (state) => state.toppingBallance
  );
  const { t } = useTranslation();
  function onChange(value) {
    console.log(`selected ${value}`);
    dispatch(inputHandler(value, "selected_city_code"));
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  function callback(prop) {
    return message.error(prop);
  }

  useEffect(() => {
    console.log("dada");
    dispatch(fetchCityCodes());
  }, []);
  return (
    <>
      <StyledContainer bounceAnimation={bounceAnimation} see={false}>
        <div className="step">
          <label>{t("page_title.balance.city")}</label>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={t("page_title.balance.city")}
            optionFilterProp="children"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {city_codes?.map((item) => (
              <Option value={item.city_code} key={item.id}>
                {item.city} ({item.city_code})
              </Option>
            ))}
          </Select>
        </div>

        <div className="step">
          <label>{t("page_title.balance.telephoneNumber")}</label>
          <Input
            style={{ width: 200 }}
            name="telephone_number"
            onChange={(e) =>
              dispatch(inputHandler(e.target.value, e.target.name))
            }
          />
        </div>
        <div className="step">
          <label></label>
          <button onClick={() => dispatch(sendNumberOfTelephone(callback))}>
            {t("page_title.balance.pastDueBalance")}
          </button>
        </div>
      </StyledContainer>

      <StyledContainer bounceAnimation={zoomOutUpAn} see={true}>
        <div className="step">
          <label>{t("page_title.balance.userName")}</label>
          <Input
            style={{ width: 200 }}
            value={costumer_data ? `${costumer_data.name}` : ""}
            disabled={true}
          />
        </div>

        <div className="step">
          <label>{t("page_title.balance.balance")}</label>
          <Input
            style={{ width: 200 }}
            value={costumer_data ? costumer_data.balance : ""}
            disabled={true}
          />
        </div>

        <div className="step">
          <label>{t("page_title.balance.totalCost")}</label>
          <Input
            style={{ width: 200 }}
            placeholder={t("page_title.balance.price_holder")}
          />
        </div>

        <div className="step">
          <label></label>
          <button>{t("page_title.balance.pay")}</button>
        </div>
      </StyledContainer>
    </>
  );
};

export default StepOne;
