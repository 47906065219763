import React from "react";
// packages
import { Select, Input } from "antd";
import { fadeInLeft, fadeInRight } from "react-animations";
import { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
// styles
import { StyledContainer } from "./styles/StepsStyles";
// anim
const bounceAnimation = keyframes`${fadeInRight}`;
const zoomOutUpAn = keyframes`${fadeInLeft}`;

const { Option } = Select;

const StepTwo = () => {
  const {t} = useTranslation();
  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  return (
    <>
      <StyledContainer bounceAnimation={bounceAnimation} see={false}>
        <div className="step">
          <label>{t("page_title.balance.user")}</label>
          <Input style={{ width: 200 }} />
        </div>
        <div className="step">
          <label></label>
          <button>{t("page_title.balance.pastDueBalance")}</button>
        </div>
      </StyledContainer>

      <StyledContainer  bounceAnimation={zoomOutUpAn} see={true}>
        <div className="step">
          <label>{t("page_title.balance.userName")}</label>
          <Input style={{ width: 200 }} disabled={true} />
        </div>

        <div className="step">
          <label>{t("page_title.balance.balance")}</label>
          <Input style={{ width: 200 }} disabled={true} />
        </div>

        <div className="step">
          <label>{t("page_title.balance.totalCost")}</label>
          <Input style={{ width: 200 }} placeholder={t("page_title.balance.price_holder")} />
        </div>

        <div className="step">
          <label>{t("page_title.balance.card")}</label>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={t("page_title.balance.select_card")}
            optionFilterProp="children"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
            <Option value="tom">Tom</Option>
          </Select>
        </div>
        <div className="step">
          <label>{t("page_title.balance.commission")}</label>
          <Input style={{ width: 200 }} disabled={true} />
        </div>
        <div className="step">
          <label></label>
          <button>{t("page_title.balance.pay")}</button>
        </div>
      </StyledContainer>
    </>
  );
};

export default StepTwo;
