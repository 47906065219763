import axios from "axios";

export const fetchSmsContact = () => {
  return function (dispatch, getState) {
    const { localNumber } = getState().sms;

    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "sms_chat_numbers",
        pars: {
          account_id: localStorage.getItem("userId"),
          local_num: localNumber,
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        return dispatch({
          type: "SMS_CONTACT",
          payload: res.data.data[0].remote_nums,
        });
      });
  };
};

export const sendSms = (message) => {
  return function (dispatch, getState) {
    const { smsApiKey, localNumber, remoteNumber } = getState().sms;
    axios
      .post("https://api.telecom1.ge/api.php", {
        lang: "ka",
        base: "users",
        function: "api_send_sms",
        pars: {
          account_id: localStorage.getItem("userId"),
          sms_apikey: smsApiKey,
          from: localNumber,
          to: `${remoteNumber}`,
          body: message,
        },
        apikey: "u4AzRTE7Mpp6R4aEzopI",
      })
      .then((res) => {
        dispatch(fetchSmsContact()) 
        dispatch({type:"SMS_FREE_SENT_TODAY", payload: res.data.sms_free_sent_today})
    });
  };
};

export const setSmsApiKeyAction = (key) => {
  return { type: "SMS_API_KEY", payload: key };
};

export const fetchNumber = () => {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "users",
        function: "voip_list",
        pars: { account_id: localStorage.getItem("userId"), status: "1" },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then(async (res) => {
        const data = res.data.data;
        data &&
        await dispatch({
          type: "FETCH_NUMBER",
          payload: data,
          defaultNumber: {
            id: data[0].acc_product_id,
            number: data[0].sip_username,
          },
        });
      });
  };
};

export const fetchPersonSms = () => {
  return function (dispatch, getState) {
    const { remoteNumber, localNumber, sms } = getState().sms;
    remoteNumber &&
      axios
        .post(process.env.REACT_APP_API_URL, {
          lang: "ka",
          base: "users",
          function: "sms_chat_data",
          pars: {
            account_id: localStorage.getItem("userId"),
            local_num: localNumber,
            remote_num: remoteNumber,
            limit: "0,1000",
          },
          apikey: process.env.REACT_APP_API_KEY,
        })
        .then(async (res) => {
          const data = await res.data.data.reverse();
          sms.length !== data.length &&
            dispatch({ type: "SMS", payload: data });
        });
  };
};

export const localHandler = (local, freesms,sms_free_sent_today) => {
  return { type: "LOCAL_DONE", payload: local,freesms:freesms,sms_free_sent_today };
};
export const remoteHandler = (remote) => {
  return { type: "REMOTE_DONE", payload: remote };
};

export const resetState = () => {
  return function (dispatch, getState) {
    //getState().servicesContentMappings.number
    dispatch({ type: "RESET_STATE" });
  };
};

const initialState = {
  sms_contact: [],
  numbers: [],
  sms: [],
  defaultNumber: { id: "", number: "" },
  localNumber: null,
  freesms: 0,
  sms_free_sent_today: 0,
  remoteNumber: null,
  smsApiKey: "",
  sendSmsSuccess: "",
  smsReceived: false,
  freeSmsSender: null
};

export const smsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NUMBER":
      return {
        ...state,
        numbers: action.payload,
        defaultNumber: action.defaultNumber,
      };
    case "LOCAL_DONE":
      return { ...state, localNumber: action.payload,freesms: action.freesms,sms_free_sent_today: action.sms_free_sent_today };
    case "REMOTE_DONE":
      return { ...state, remoteNumber: action.payload };
    case "SMS_CONTACT":
      return { ...state, sms_contact: action.payload };
    case "SMS":
      return { ...state, sms: action.payload, smsReceived: action.received };
    case "SMS_RECEIVED":
      return { ...state, smsReceived: action.received };
    case "SMS_API_KEY":
      return { ...state, smsApiKey: action.payload };
      case "SMS_FREE_SENT_TODAY": 
      return {...state, freeSmsSender: action.payload}
    case "RESET_STATE":
      return (state = initialState);
    default:
      return state;
  }
};
