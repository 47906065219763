import React, { useState } from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { resetState } from "../../../redux/reducers/dashboard/sms";
import {
  GlobalOutlined,
  PhoneOutlined,
  FileDoneOutlined,
  PaperClipOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;
const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5", "sub6"];

const ServicesMenu = ({ setServiceContent }) => {
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div className="menu">
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={["1"]}
      >
        <SubMenu
          key="sub1"
          onTitleClick={() => setServiceContent(1)}
          title={
            <span>
              <GlobalOutlined />
              <span>{t("menu.services.title")}</span>
            </span>
          }
        >
          <Menu.Item key="1" onClick={() => setServiceContent(1)}>
            {t("menu.services.my_services")}
          </Menu.Item>
          <Menu.Item key="2" onClick={() => setServiceContent(6)}>
            {t("menu.services.my_orders")}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub2"
          icon={<PhoneOutlined />}
          title={t("menu.telephone.title")}
          onTitleClick={() => setServiceContent(2)}
        >
          <Menu.Item key="3" onClick={() => setServiceContent(2)}>
            SMS
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={() => {
              dispatch(resetState());
              return setServiceContent(8);
            }}
          >
            {t("menu.telephone.extraction")}
          </Menu.Item>
          <Menu.Item key="5" onClick={() => setServiceContent(9)}>
            {t("menu.telephone.number_settings")}
          </Menu.Item>
        </SubMenu>
        {/**        <SubMenu
          key="sub3"
          icon={<MailOutlined />}
          title="A2P SMS"
          onTitleClick={() => setServiceContent(3)}
        >
          <Menu.Item key="6" onClick={() => setServiceContent(3)}>
            კონკრეტულ პირთან გაგზავნა
          </Menu.Item>
          <Menu.Item key="7" onClick={() => setServiceContent(10)}>
            მასიური გაგზავნა
          </Menu.Item>
          <Menu.Item key="8" onClick={() => setServiceContent(11)}>
            საფირმო სახელწოდება
          </Menu.Item>
          <Menu.Item key="9" onClick={() => setServiceContent(12)}>
            ამონაწერი
          </Menu.Item>
          <Menu.Item key="10" onClick={() => setServiceContent(13)}>
            ბაზის ატვირთვა
          </Menu.Item>
          <Menu.Item key="11" onClick={() => setServiceContent(14)}>
            SMS API
          </Menu.Item>
        </SubMenu> */}

        <SubMenu
          key="sub4"
          icon={<FileDoneOutlined />}
          title={t("menu.payments.title")}
          onTitleClick={() => setServiceContent(4)}
        >
          <Menu.Item key="12">{t("menu.payments.payments")}</Menu.Item>
        </SubMenu>

        <SubMenu
          key="sub5"
          icon={<PaperClipOutlined />}
          title={t("menu.documents.title")}
          onTitleClick={() => setServiceContent(5)}
        >
          <Menu.Item key="13" onClick={() => setServiceContent(5)}>
            {t("menu.documents.contract")}
          </Menu.Item>
          <Menu.Item key="14" onClick={() => setServiceContent(15)}>
            {t("menu.documents.surrender")}
          </Menu.Item>
          <Menu.Item key="15" onClick={() => setServiceContent(16)}>
            {t("menu.documents.invoice")}
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="sub6"
          icon={<SettingOutlined />}
          title={t("menu.options.title")}
          onTitleClick={() => setServiceContent(17)}
        >
          <Menu.Item key="16" onClick={() => setServiceContent(17)}>
            {t("menu.options.add_an_address")}
          </Menu.Item>
          <Menu.Item key="17" onClick={() => setServiceContent(18)}>
            {t("menu.options.personal_information")}
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
};

export default ServicesMenu;
