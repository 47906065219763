import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { message } from "antd";

import { StyledPinBox } from "./style/TimerStyle";

const Timer = ({
  pinCodeHandler,
  pinNotification,
  forgotPassword,
  modal,
  registerPhoneNumber,
  forgotPasswordPhoneNumber,
  setApiPinCodeFnc
}) => {
  const [time, setTime] = useState(3 * 60);
  const [minute, setMinute] = useState(null);
  const [second, setSecond] = useState(null);
  const [showTimer, setShowTimer] = useState(false);

  const api = () => {
      setShowTimer(true)
    if (forgotPassword) {
      console.log(forgotPasswordPhoneNumber)
        axios
          .post(process.env.REACT_APP_API_URL, {
            lang: "ka",
            base: "users",
            function: "reset_code",
            pars: { to: `995${forgotPasswordPhoneNumber}` },
            apikey: process.env.REACT_APP_API_KEY,
          })
          .then((res) => console.log(res.data));
      } else {
        axios
          .post(process.env.REACT_APP_API_URL, {
            lang: "ka",
            base: "users",
            function: "send_code",
            pars: { to: `995${registerPhoneNumber}` },
            apikey: process.env.REACT_APP_API_KEY,
          })
          .then((res) => {
            console.log(res.data)
            console.log(process.env.REACT_APP_API_URL)
            console.log(process.env.REACT_APP_API_KEY)
            setApiPinCodeFnc(res.data.smscode)
          });
      }
      message.success("PIN კოდი გამოგზავნილია!");
  }
  const updateCountDown = useCallback(() => {
    setShowTimer(true)
    setTime((oldTime) => {
      const minutes = Math.floor(oldTime / 60);
      let seconds = oldTime % 60;
      setMinute(minutes);
      setSecond(seconds);
      if (oldTime === 0) {
        setShowTimer(false)
        return (oldTime = 3 * 60);
      } else {
        return oldTime - 1;
      }
    });
  }, [time, minute, second]);

  useEffect(() => {
    let timer =
      showTimer &&
      setInterval(() => {
        updateCountDown();
      }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [showTimer]);
  return (
    <div>
      {pinNotification ? (
        <StyledPinBox
          forgotPassword={forgotPassword}
          disableTimer={showTimer}
          modal={modal}
        >
          <button
            onClick={api}
            className="pin"
            type="button"
            disabled={showTimer}
          >
            {showTimer ? (
              <>
                {minute}:{second}
              </>
            ) : (
              <>PIN კოდი</>
            )}
          </button>
          <input
            type="text"
            className="pin__input"
            maxLength="6"
            onChange={pinCodeHandler}
          />
        </StyledPinBox>
      ) : (
        ""
      )}
    </div>
  );
};

export default Timer;
