import axios from "axios";

export const inputHandler = (value,name) => {
    return {type: "INPUT_HANDLER", payload: value, target: name}
}

const cityCodesAction = (data) => {
  return { type: "FETCH_CITY_CODES", payload: data };
};
const costumerDataAction = (data) => {
    return {type: "COSTUMER_DATA",payload: data}
}
const subscriberNumberAction = (data) => {
    return {type: "SUBSCRIBER_DATA",payload: data}
}

export const fetchCityCodes = () => {
  return function (dispatch, getState) {
    const { lang } = getState().lang;
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: lang,
        base: "info",
        function: "list_city",
        pars: { lang: lang },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(cityCodesAction(res.data.data));
      });
  };
};

export const sendNumberOfTelephone = (callback) => {
  return function (dispatch, getState) {
    const { lang } = getState().lang;
    const { telephone_number,selected_city_code } = getState().toppingBallance;
    if(!selected_city_code) {
        return callback("აირჩიეთ ქალაქი")
    } else if(!telephone_number) {
        return callback("შეიყვანეთ ტელეფონის ნომერი")
    }
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: lang,
        base: "info",
        function: "nbalance",
        pars: { number: `${selected_city_code}${telephone_number}` },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        dispatch(costumerDataAction(res.data.data));
      });
  };
};

export const sendNumberOfInternet = (callback) => {
    return function (dispatch, getState) {
      const { lang } = getState().lang;
      const { internet_number } = getState().toppingBallance;
         if(!internet_number) {
          return callback("შეიყვანეთ აბონენტის ნომერი")
      }
      axios
        .post(process.env.REACT_APP_API_URL, {
          lang: lang,
          base: "info",
          function: "ibalance",
          pars: { abnumber: internet_number },
          apikey: process.env.REACT_APP_API_KEY,
        })
        .then((res) => {
          dispatch(subscriberNumberAction(res.data));
        });
    };
  };

const initialState = {
  city_codes: [],
  costumer_data: null,
  subscriber_data: null,
  telephone_number: null,
  internet_number: null,
  selected_city_code: null,
};

export const toppingBallanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CITY_CODES":
      return { ...state, city_codes: action.payload };
    case "INPUT_HANDLER":
        return {...state, [action.target]: action.payload}
    case "COSTUMER_DATA":
        return {...state, costumer_data: action.payload}
    case "SUBSCRIBER_DATA":
        return {...state, subscriber_data: action.payload}
    default:
      return state;
  }
};
