import axios from "axios";

// Actions

export const userSuccess = (orders) => {
  return {
    type: "ORDERS_REQ",
    payload: orders,
  };
};

export const numberCreate = (number) => {
  return {type:"NUMBER_CREATE", payload:number.number}
}

export const fetchUserOrders = () => {
  return function (dispatch, getState) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "orders",
        function: "order_list",
        pars: {
          account_id: localStorage.getItem("userId"),
          token: localStorage.getItem("token"),
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
        const orders = res.data.data;
        dispatch(userSuccess(orders));
      });
  };
};

export const userOrderDelete = (id) => {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_URL, {
      lang: "ka",
      base: "orders",
      function: "order_del",
      pars: { id: id, account_id: localStorage.getItem("userId"), token: localStorage.getItem("token") },
      apikey: process.env.REACT_APP_API_KEY,
    }).then((res => {
      dispatch({type: "ORDER_DELETE",payload: id})
    }))
  };
};

// state
const initialState = {
  orders: [],
  telephoneNumberForPacketInfo: ""
};

export const userOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ORDERS_REQ":
      return { orders: action.payload };
    case "ORDER_DELETE":
      return {...state, orders: state.orders.filter((x) => x.id !== action.payload)}
      case "NUMBER_CREATE": 
      return {...state, telephoneNumberForPacketInfo: action.payload}
    default:
      return state;
  }
};
