import axios from "axios";

export const addOrder = (address, order,order_id, category, phone,address_name) => {
  return function (dispatch, getState) {
    const {user} = getState().userInfo
    console.log(user)
    axios
      .post(process.env.REACT_APP_API_URL, {
        lang: "ka",
        base: "orders",
        function: "order_add",
        pars: {
          account_id: localStorage.getItem("userId"),
          category: category,
          product_id:  order_id,
          order_address: address,
          info: { voip_number: phone.number, price: phone.buy_cost },
          token: localStorage.getItem("token"),
        },
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((res) => {
          axios.post(process.env.REACT_APP_API_URL, {
            lang: "ka",
            base: "users",
            function: "gen_invoice",
            pars: {
              customerid: user.account_id,
              cfname: user.first_name,
              clname: user.last_name,
              cid:user.idcard,
              sorderid: order.display_name,
              norderid: phone.number,
              samount: order.base_price,
              namount: phone.buy_cost,
              caddr: address_name.addres_name
            },
            apikey: process.env.REACT_APP_API_KEY
          }).then((res) => console.log(res))
      });
  };
};
